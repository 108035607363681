
import { Component, Mixins } from 'vue-property-decorator'

import { Breadcrumb } from '@/dsl/atoms/Breadcrumbs'

import { getBreadcrumbsFactoryForRoute } from '@/shared'
import { AmplitudeType, AppAmplitudeEvent, IAmplitude } from '@/shared/services/amplitude'

import { Event as EventData, EventModel } from '@/modules/events/models'
import { EventCard } from '@/modules/events/components/organisms/EventCard'

import { WishlistMixin } from '../shared/mixins'
import { WishlistItem } from '../services/wishlist'

@Component<Favourites>({
  name: 'Favourites',
  components: { EventCard },
  mounted (): void {
    this.amplitude.emit(AppAmplitudeEvent.ON_OPEN_SAVED_EVENTS)
  }
})
export class Favourites extends Mixins(WishlistMixin) {
  public events: EventModel[] = []

  protected readonly amplitude =
    this.$container.get<IAmplitude>(AmplitudeType)

  public get breadcrumbs(): Breadcrumb[] {
    if (!this.$t) {
      return []
    }

    return getBreadcrumbsFactoryForRoute(this.$route, undefined, this.$t.bind(this))
  }

  public get retrieveItems (): EventModel[] {
      const rawEvents = this.wishlist?.items ?? []

      return rawEvents.map((event: WishlistItem<EventData>) => {
        return EventModel.hydrate<EventModel>(event.item)
      })
  }
}
export default Favourites
